import React, {FC, RefObject, useEffect, useLayoutEffect, useState} from 'react'
import {Countdown} from './video/Countdown'
import {LiveStreamPreview} from './video/LiveStreamPreview'
import {Player} from './video/Player'
import useMediaRecorder from '@wmik/use-media-recorder'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {CheckIcon, RecordIcon} from '../elements/Icons'
import {getAllSupportedMimeTypes, getMediaStreamConstraints} from '../utils/camera.util'
import {Stores} from '../stores'
import {useStores} from '../utils/hooks'
import {VIDEO_HEIGHT} from '../utils/constants'
import {log} from '../utils/log.util'
import {Button, PageLoader, Paper, breakpoints, colors, textBold, textNormal} from '@webscale-oy/vaalikone-common-ui-base'
import { awsRum } from '../utils/awsRum.util'

type Props = {
  upload: (media: Blob, videoAspect: string, image: Blob) => Promise<void>
  resetVideoState: () => void
  disabled?: boolean
  topRef: RefObject<any>
}

type ImplProps = Props & {
  constraints: MediaStreamConstraints
  mediaRecorderOptions: {mimeType: string}
}

export const VideoRecorder: FC<Props> = props => {
  const [constraints, setConstraints] = useState<MediaStreamConstraints>()
  const [options, setOptions] = useState<{mimeType: string}>()
  const {dialogStore} = useStores() as Stores
  const {t} = useTranslation()

  useEffect(() => {
    const initializeCamera = async () => {
      let cameraConstraints: MediaStreamConstraints | null = null
      let mediaOptions: {mimeType: string} | null = null
      try {
        cameraConstraints = await getMediaStreamConstraints()
        mediaOptions = {mimeType: getAllSupportedMimeTypes('video')[0]}
        setConstraints(cameraConstraints)
        setOptions(mediaOptions)
      } catch (e: any) {
        awsRum?.recordEvent('cameraInitError', {error: e.message, usedConstraints: cameraConstraints, mediaOptions})
        awsRum?.recordError(e)
        log('Error:', e.message, e)
        let message = e.message
        if (message.toLowerCase().includes('denied')) {
          message = t('pages.videoPage.camOrMicNotAllowed')
        }
        await dialogStore.openDialog(t('pages.videoPage.cameraInitUnknownError'), message, 'error')
      }
    }
    initializeCamera()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (constraints && options) {
    return (
      <VideoRecorderImpl
        upload={props.upload}
        resetVideoState={props.resetVideoState}
        topRef={props.topRef}
        constraints={constraints}
        mediaRecorderOptions={options}
      />
    )
  }
  return null
}

const VideoRecorderImpl: FC<ImplProps> = ({upload, resetVideoState, constraints, mediaRecorderOptions, topRef}) => {
  const [uploading, setIsUploading] = useState(false)
  const [initialized, setInitialized] = useState<boolean>(false)
  const {status, mediaBlob, stopRecording, startRecording, liveStream, getMediaStream, error, clearMediaStream} = useMediaRecorder({
    recordScreen: false,
    mediaRecorderOptions: {mimeType: mediaRecorderOptions.mimeType},
    blobOptions: {type: mediaRecorderOptions.mimeType},
    mediaStreamConstraints: constraints
  })
  const [countdown, setCountdown] = useState<boolean>(false)
  const [recordCountdown, setRecordCountdown] = useState<boolean>(false)
  const [screenshot, setScreenshot] = useState<Blob | null>(null)
  const [videoAspect, setVideoAspect] = useState<string | null>(null)
  const {t} = useTranslation()

  useLayoutEffect(() => {
    const initVideo = async () => {
      if (status === 'idle' && !initialized) {
        await getMediaStream()
        setInitialized(true)
      }
    }
    initVideo()
    topRef.current?.scrollIntoView()
    return () => {
      stopRecording()
      clearMediaStream()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const recordStream = async () => {
    setCountdown(false)
    await startRecording()
    setRecordCountdown(true)
  }

  const recordingFinished = async () => {
    setRecordCountdown(false)
    await stopRecording()
  }

  const uploadVideo = async () => {
    setIsUploading(true)
    try {
      await upload(mediaBlob!, videoAspect!, screenshot!)
      setIsUploading(false)
    } catch (e) {
      setIsUploading(false)
    }
  }

  const getErrorMessage = (err: Error | null) => {
    switch (err?.name) {
      case 'NotAllowedError':
        return t('pages.videoPage.cameraNotAllowed')
      default:
        console.error(err)
        return `${t('pages.videoPage.cameraInitUnknownError')}: ${err?.message}`
    }
  }

  if (status === 'failed') {
    return (
      <VideoArea>
        <ErrorArea>
          <ErrorMessage>{getErrorMessage(error)}</ErrorMessage>
          <Button onClick={async () => await getMediaStream()}>{t('pages.videoPage.refreshCamera')}</Button>
        </ErrorArea>
      </VideoArea>
    )
  }

  if (uploading) {
    return (
      <LoadingIndicatorBackground>
        <PageLoader wrapperHeight="200px" title={t('videoPage.videoProcessing')} />
      </LoadingIndicatorBackground>
    )
  }

  const buttonsDisabled = !['stopped'].includes(status)
  return (
    <>
      <VideoArea>
        {countdown && (
          <Countdown
            seconds={5}
            timerDone={() => recordStream()}
            render={secondsLeft => <RecordStartTime>{secondsLeft}</RecordStartTime>}
          />
        )}
        {recordCountdown && (
          <Countdown
            seconds={60}
            timerDone={() => recordingFinished()}
            render={secondsLeft => (
              <RecordTimeLeft>
                <RecordIndicator /> {secondsLeft} s
              </RecordTimeLeft>
            )}
          />
        )}
        {!['recording', 'acquiring_media', 'stopped'].includes(status) && !countdown && (
          <RecButton
            onClick={async () => {
              setCountdown(true)
            }}
          />
        )}
        {status === 'recording' && (
          <StopButton onClick={recordingFinished}>
            <StopButtonRect />
          </StopButton>
        )}
        <LiveStreamPreview stream={liveStream} setVideoAspect={setVideoAspect} />
        {status === 'stopped' && mediaBlob && <Player srcBlob={mediaBlob} setScreenshot={setScreenshot} />}
      </VideoArea>
      <ButtonArea>
        <RerecordButton
          onClick={async () => {
            resetVideoState()
            await getMediaStream()
          }}
          disabled={buttonsDisabled}
        >
          <ButtonIcon>
            <RecordIcon />
          </ButtonIcon>
          {t('pages.videoPage.rerecordButton')}
        </RerecordButton>
        <SaveButton onClick={() => uploadVideo()} disabled={buttonsDisabled}>
          <ButtonIcon>
            <CheckIcon />
          </ButtonIcon>
          {t('pages.videoPage.saveButton')}
        </SaveButton>
      </ButtonArea>
      <HelpText>{t('videoPage.videoHelpText')}</HelpText>
    </>
  )
}

const VideoArea = styled.div`
  width: 700px;
  height: ${VIDEO_HEIGHT}px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`
const RecordTimeLeft = styled.span`
  display: block;
  position: absolute;
  top: 4px;
  right: 12px;
  width: 60px;
  height: 20px;
  background-color: rgb(50, 50, 50, 0.5);
  z-index: 1000;
  color: white;
  ${textBold};
`

const LoadingIndicatorBackground = styled(Paper)`
  background-color: ${colors.grey_25};
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 420px;
  min-width: 760px;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    min-width: 100vw;
  }
`

const RecordStartTime = styled.span`
  display: inline;
  position: absolute;
  text-shadow: 1px 1px 2px rgba(185, 185, 185, 0.75);
  color: white;
  ${textBold};
  font-size: 48px;
`

const RecordIndicator = styled.span`
  display: inline-block;
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 5px;
`
const RecButton = styled.span`
  display: flex;
  width: 64px;
  height: 64px;
  border: 10px solid white;
  background-color: red;
  border-radius: 32px;
  opacity: 0.5;
  position: absolute;
  z-index: 1000;
`
const StopButton = styled.span`
  display: flex;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: white;
  opacity: 0.5;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1000;
`
const StopButtonRect = styled.span`
  display: block;
  width: 32px;
  height: 32px;
  background-color: red;
`
const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 20px 0;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`
const SaveButton = styled(Button)`
  border-radius: 2px;
  height: 56px;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    margin: 10px 0;
  }
  &:disabled {
    border: 1px solid #aaa;
    color: #aaa;
    background-color: white;
    &:hover {
      background-color: white;
    }
  }
  border: 1px solid #259f78;
  color: #259f78;
  background-color: white;
  &:hover {
    background-color: #bcf7e5;
  }
`
const RerecordButton = styled(Button)`
  border-radius: 2px;
  height: 56px;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    margin: 10px 0;
  }
  &:disabled {
    border: 1px solid #aaa;
    color: #aaa;
    background-color: white;
    &:hover {
      background-color: white;
    }
  }
  border: 1px solid #e53451;
  color: #e53451;
  background-color: white;
  &:hover {
    background-color: #f7c3cc;
  }
`
const ButtonIcon = styled.span`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`
const ErrorArea = styled.div`
  display: flex;
  flex-direction: column;
`
const ErrorMessage = styled.div`
  margin-bottom: 20px;
  padding: 10px 20px;
  border: 1px solid #e53451;
  color: #e53451;
  background-color: white;
  border-radius: 4px;
`
const HelpText = styled.p`
  ${textNormal};
  color: ${colors.grey_400};
`
