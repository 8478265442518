import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Stores } from '../stores'
import { IAnswer } from '../types/answer'
import { IQuestion } from '../types/question'
import { useStores } from '../utils/hooks'
import { AnswersWrapper } from '../templates/AnswersWrapper.template'
import { checkColorVisibility, deepClone } from '../utils/helper.util'
import { getAnswerFieldByType, getInitialValueByType } from '../utils/answer.util'
import { I18nHelper } from '../i18n/i18nHelper'
import { PageBottomProgress } from '../elements/PageBottomProgress'
import { ResponsiveForm } from '../elements/ResponsiveForm'
import { theme } from '../theme'
import {
  AnswerOneToFive,
  Bleed,
  FloatingProgress,
  FormRow,
  OneToFiveInput,
  PaperWithStatus,
  PriorityList,
  Span,
  YesNoQuestion,
  breakpoints,
  colors,
  determineErrorStatus,
  electionTheme,
  spacing,
  subheaderLight,
  textH1,
  textH3,
  textH4,
  useForm
} from '@webscale-oy/vaalikone-common-ui-base'
import { NextBackNav } from '@webscale-oy/vaalikone-common-ui-candidate'
import { FormMultiLangTextarea } from '../components/FormElements/TextArea/MultiLangTextarea'
import { ElectionType } from '../types/election'

const REASONING_LIMIT = 1000

interface AnswerForm {
  [key: number]: any
}

const QuestionsPageComponent: React.FC = observer(() => {
  const [navClickCount, setNavClickCount] = useState<number>(0)
  const [navigateTo, setNavigateTo] = useState<string | undefined>(undefined)
  const { answerStore, notificationStore, questionStore, candidateStore, mediapartnerStore } = useStores() as Stores
  const { candidateId } = useParams<{ candidateId: string }>()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { item, setNestedValue, setItem, hasErrors } = useForm<AnswerForm>()
  const [errors, setErrors] = useState<AnswerForm>({})
  const [updatebleAnswerId, setUpdatebleAnswerId] = useState<number | undefined>(undefined)
  const answers = answerStore!.answers
  const readOnly = candidateStore.candidate?.status === 'COMPLETED'
  const { pathname } = useLocation()
  const categories = (() => {
    if (pathname.includes('kuntavaalit')) {
      return questionStore.municipalityCategories
    }
    if (pathname.includes('aluevaalit')) {
      return questionStore.countyCategories
    }
    return questionStore.categories
  })()

  const numOfAnswers = useMemo(() => {
    if (pathname.includes('kuntavaalit')) {
      return questionStore.municipalityCategories.flatMap(c => c.questions).filter(q => !!answers.find(a => a.question_id === q.id)).length
    }
    if (pathname.includes('aluevaalit')) {
      return questionStore.countyCategories.flatMap(c => c.questions).filter(q => !!answers.find(a => a.question_id === q.id)).length
    }
    return 0
  }, [answers, pathname, questionStore.countyCategories, questionStore.municipalityCategories])

  useEffect(() => {
    const state = {} as any
    const errorsObj = {} as any

      ; (answers || []).forEach((answer: IAnswer) => {
        const { question_id, candidate_id, ...rest } = answer
        state[answer.question_id] = deepClone(rest)
        errorsObj[answer.question_id] = false
      })
    setItem(state)
    setErrors(errorsObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerStore!.answers])

  const priortyListAnswerIsValid = (question: IQuestion, data: Partial<IAnswer>) => {
    if (!!data.options_answer) {
      return question.options!.length === data.options_answer.length
    }
    return false
  }

  const updateAnswer = async (question: IQuestion, data: Partial<IAnswer>) => {
    const savedAnswer = answers.find(a => a.question_id === question.id)?.[getAnswerFieldByType(question.type)]
    const answerChanged = savedAnswer !== data[getAnswerFieldByType(question.type)]
    const reasoningChanged =
      data[I18nHelper.getExplanationLangKey('fi')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('fi')] ||
      data[I18nHelper.getExplanationLangKey('sv')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('sv')] ||
      data[I18nHelper.getExplanationLangKey('en')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('en')] ||
      data[I18nHelper.getExplanationLangKey('ru')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('ru')] ||
      data[I18nHelper.getExplanationLangKey('sme')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('sme')] ||
      data[I18nHelper.getExplanationLangKey('ara')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('ara')] ||
      data[I18nHelper.getExplanationLangKey('ukr')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('ukr')] ||
      data[I18nHelper.getExplanationLangKey('som')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('som')]

    const answer: IAnswer = {
      ...data,
      question_id: question.id,
      candidate_id: Number(candidateId)!,
      election_type: data.election_type ?? (pathname.includes('kuntavaalit') ? ElectionType.Municipality : ElectionType.County)
    }

    if (question.type === 'PRIORITY_LIST' && !priortyListAnswerIsValid(question, data)) {
      notificationStore!.createNotification(t('errors.optionsMissing'), 'error')
      return undefined
    } else {
      if (reasoningChanged || answerChanged) {
        await saveSingle(answer)
      }
    }
  }

  const saveSingle = async (answer: IAnswer) => {
    try {
      setUpdatebleAnswerId(answer.question_id)
      await answerStore!.postAnswer(answer)
    } catch (error) {
      setErrors({ ...errors, [answer.question_id]: t('textarea.requiredNotAnswered') })
      notificationStore!.createNotification(t('errors.unexpectedError'), 'error')
    }
  }

  const renderQuestion = (question: IQuestion) => {
    switch (question.type) {
      case 'ONE_TO_FIVE':
        return (
          <Bleed $left={{ base: 'large', tablet: 'none' }} $right={{ base: 'large', tablet: 'none' }}>
            <OneToFiveInput
              onChange={value => {
                setNestedValue(`${question.id}.answer`)(value)
                updateAnswer(question, { ...item[question.id], answer: value })
              }}
              value={item[question.id]?.answer}
              title={''}
              ballSize={'45px'}
              hideNeutral
              lineThickness={'1px'}
              disabled={readOnly}
            />
          </Bleed>
        )
      case 'PRIORITY_LIST':
        return (
          <PriorityList
            onChange={async values => {
              setNestedValue(`${question.id}.options_answer`)(values)
              if (values && values.length === question.options?.length) {
                await updateAnswer(question, { ...item[question.id], options_answer: values })
              }
            }}
            options={
              question.options?.map(opt => ({
                id: opt.id,
                title: I18nHelper.getTranslatedPriorityListOption(opt, i18n)
              })) || []
            }
            questionId={question.id}
            translations={{
              done: t('priorityList.done'),
              notDone: t('priorityList.notDone'),
              selectMostImportant: t('priorityList.selectMostImportant')
            }}
            currentLang={i18n.language}
            selected={item[question.id]?.options_answer}
            disabled={readOnly}
          />
        )
      case 'YES_NO':
        return (
          <YesNowWrapper>
            <YesNoQuestion
              onChange={value => {
                setNestedValue(`${question.id}.answer`)(value)
                updateAnswer(question, { ...item[question.id], answer: value })
              }}
              answer={item[question.id]?.answer}
              disabled={readOnly}
              appearance={'BUTTON'}
              valueForYes={5}
              valueForNo={1}
            />
          </YesNowWrapper>
        )
      default:
        return null
    }
  }

  const nextPath = useMemo(() => {
    if (mediapartnerStore.hasMunicipalityQuestions && pathname.includes('kuntavaalit')) {
      return pathname.replace('kysymykset', 'mediakumppanit')
    }
    if (mediapartnerStore.hasCountyQuestions && pathname.includes('aluevaalit')) {
      return pathname.replace('kysymykset', 'mediakumppanit')
    }
    return `/${candidateId}/yhteenveto`
  }, [candidateId, mediapartnerStore.hasCountyQuestions, mediapartnerStore.hasMunicipalityQuestions, pathname])

  const renderNavActions = () => (
    <NextBackNav
      backTo={() => onNavigate(pathname.replace('video', 'kysymykset'))}
      nextTo={() => onNavigate(nextPath)}
      translations={{
        next: t('nextBackNav.continue'),
        back: t('nextBackNav.back'),
        finish: t('nextBackNav.finish')
      }}
    />
  )

  const onNavigate = async (to: string) => {
    setNavigateTo(to)
    setNavClickCount(navClickCount + 1)
  }

  const questionCount = (() => {
    if (pathname.includes('kuntavaalit')) {
      return questionStore.municipalityCategories.flatMap(c => c.questions).length
    }
    if (pathname.includes('aluevaalit')) {
      return questionStore.countyCategories.flatMap(c => c.questions).length
    }
    return 0
  })()

  let questionNumber = 0
  return (
    <AnswersWrapper
      header={t('questionPage.header')}
      continueCondition={numOfAnswers === questionCount}
      navigationPath={navigateTo}
      navigationClickCount={navClickCount}
    >
      <ResponsiveForm
        onSubmit={async () => {
          if (!hasErrors) {
            navigate('/yhteenveto')
          }
        }}
      >
        {Object.values(categories).map(c =>
          Object.values(c.questions).map((q, qI) => {
            questionNumber += 1
            const answerFieldValue = answers && answers.find(a => a.question_id === q.id)?.[getAnswerFieldByType(q.type)]
            return (
              <QuestionRowContainer key={q.id}>
                {qI === 0 && <CategoryHeader>{I18nHelper.getTranslatedCategory(c, i18n)}</CategoryHeader>}
                <PaperWithStatus
                  paperState={determineErrorStatus(
                    getInitialValueByType(q.type),
                    answerFieldValue,
                    errors[q.id],
                    answerStore?.loading && updatebleAnswerId === q.id
                  )}

                >
                  <ComponentWrapper>
                    <QuestionHeader>
                      <QuestionCountSection>
                        <Span $fontSize="4xl" $fontWeight="bold">{questionNumber}</Span> <Span $fontSize="xl">/ {questionCount}</Span>
                      </QuestionCountSection>
                      {I18nHelper.getTranslatedQuestion(q, i18n)}
                    </QuestionHeader>
                    <QuestionContainer>{renderQuestion(q)}</QuestionContainer>
                    <FormMultiLangTextarea
                      onChange={(lang, val) => {
                        if (val.length > REASONING_LIMIT) {
                          setErrors({ ...errors, [q.id]: { ...errors[q.id], textarea: t('textarea.overMax') } })
                        }
                        setNestedValue(`${q.id}.explanation_${lang}`)(val)
                      }}
                      languages={{
                        fi: t('textarea.fi'),
                        sv: t('textarea.sv'),
                        en: t('textarea.en'),
                        ru: t('textarea.ru'),
                        sme: t('textarea.sme'),
                        ara: t('textarea.ara'),
                        ukr: t('textarea.ukr'),
                        som: t('textarea.som')
                      }}
                      activeLang={i18n.language}
                      value={{
                        fi: item[q.id]?.[`explanation_fi`],
                        sv: item[q.id]?.[`explanation_sv`],
                        en: item[q.id]?.[`explanation_en`],
                        ru: item[q.id]?.[`explanation_ru`],
                        sme: item[q.id]?.[`explanation_sme`],
                        ara: item[q.id]?.[`explanation_ara`],
                        ukr: item[q.id]?.[`explanation_ukr`],
                        som: item[q.id]?.[`explanation_som`]
                      }}
                      onBlur={async (lang, value) => {
                        if (!value || (value && value.length <= REASONING_LIMIT)) {
                          updateAnswer(q, { ...item[q.id], [`explanation_${lang}`]: value ? value : null })
                        }
                      }}
                      maxChars={REASONING_LIMIT}
                      errorMessage={errors[q.id] && errors[q.id].textarea ? errors[q.id].textarea : undefined}
                      label={t('textarea.reasoningLabel')}
                      disabled={readOnly}
                      scrollShadow={{
                        shadowColor: theme.color.dropShadow
                      }}
                    />
                  </ComponentWrapper>
                </PaperWithStatus>
              </QuestionRowContainer>
            )
          })
        )}
        <PageBottomProgress
          infoTextBeginning={t('questionPage.infoBeginning')}
          infoTextEnd={t('questionPage.infoEnding')}
          amountOfAnswers={numOfAnswers}
          amountOfQuestions={questionCount}
        />
        {categories.length > 0 && renderNavActions()}
        <FloatingProgress
          saving={answerStore?.loading}
          total={questionCount}
          done={numOfAnswers}
          translations={{ saving: t('floater.saving'), saved: t('floater.saved') }}
        />
      </ResponsiveForm>
    </AnswersWrapper>
  )
})

export const QuestionsPage = QuestionsPageComponent

const CategoryHeader = styled.h2<{ bgColor?: string }>`
  ${textH3};
  color: ${({ theme, bgColor }) => checkColorVisibility(theme, bgColor)};
  margin: 40px 0 20px 0;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: ${({ theme }) => theme.radiusLarge};
  margin-top: 0;
`

const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 ${spacing.space_48}px 0;
  ${AnswerOneToFive} {
    p {
      ${subheaderLight};
      font-size: 12px;
      color: ${colors.grey_800};
    }
  }
`

const QuestionCountSection = styled.div`
  > h1 {
    display: inline;
    ${textH1};
  }
  > h4 {
    display: inline;
    color: ${({ theme }) => theme.textSecondary};
    ${textH4};
  }
  margin: 10px 0;
`

const QuestionHeader = styled.h3`
  ${textH3}
`
const ComponentWrapper = styled(FormRow)`
  display: flex;
  justify-content: space-between;
  width: 760px;
  flex-direction: column;

  ${FormMultiLangTextarea} {
    > div {
      > label {
        text-align: left;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`

export const YesNowWrapper = styled.div`
  button {
    border: 1px solid ${electionTheme.primary};
  }
`

const QuestionRowContainer = styled.div`
  ${PaperWithStatus} {
    margin-bottom: ${spacing.space_24}px;
  }
`
