import React, {useEffect} from 'react'
import styled from 'styled-components'
import {getVideoWidthAspect, VideoAspect} from '../../utils/camera.util'
import {VIDEO_HEIGHT} from '../../utils/constants'

type Props = {
  videoId?: string
  videoAspect?: VideoAspect
}

export const AreenaVideo: React.FC<Props> = ({videoId, videoAspect}) => {
  const ref = React.useRef<HTMLDivElement>(null)
  useEffect(() => {
    let player: any
    const embedPlayer = async () => {
      if (ref.current && videoId) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        player = await window.ylePlayer.render({
          element: ref.current,
          props: {id: videoId, noRecommendations: true, analytics: false, aspectRatio: videoAspect || '1:1'}
        })
      }
    }
    const promise = embedPlayer()
    return () => {
      promise.then(() => {
        if (player) {
          player.destroy()
        }
      })
    }
  }, [videoAspect, videoId])

  if (!videoId) {
    return null
  }

  return <VideoArea ref={ref} videoWidth={VIDEO_HEIGHT * getVideoWidthAspect(videoAspect || '1:1')} />
}

const VideoArea = styled.div<{videoWidth: number}>`
  height: ${VIDEO_HEIGHT}px;
  width: ${({videoWidth}) => videoWidth}px;
  & video {
    left: 0;
  }
`
