import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { spacing, radius, color } from '@yleisradio/yle-design-system'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { PillButton } from '../elements/Button'
import { Stores } from '../stores'
import { useStores } from '../utils/hooks'
import { MainContainer, MainWrapper } from '../elements/Layout'
import { WelcomeBox } from '../elements/welcome.box'
import { TopNavBar } from '@webscale-oy/vaalikone-common-ui-candidate'
import { FormDropdown, FormInput, Inline, layoutSpacing, PageLoader, Span } from '@webscale-oy/vaalikone-common-ui-base'
import { ElectionType } from '../types/election'
import municipalities from '../municipalities.json'
import { isCountyElection, matchesCountyElection, matchesMunicipalityElection } from '../utils/helper.util'

const HELSINKI = '091'

const emailRegex = /\S+@\S+\.\S+/

const CreateNewPageComponent = observer(() => {
  const { t, i18n: { language } } = useTranslation()
  const { registrationStore, notificationStore } = useStores() as Stores
  const {
    authenticateCandidate,
    fetchElectionData,
    createNewCandidate,
    setNewCandidate,
    setError,
    error,
    loading,
    newCandidate,
    constituencies,
    parties
  } = registrationStore
  const location = useLocation()
  const navigate = useNavigate()

  const municipalityConstituencies = constituencies.filter(c => c.election_type === ElectionType.Municipality)
  const countyConstituencies = constituencies.filter(c => c.election_type === ElectionType.County)

  const municipalityParties = parties.filter(p => p.election_type === ElectionType.Municipality || p.election_type === ElectionType.Both)
  const countyParties = parties.filter(p => p.election_type === ElectionType.County || p.election_type === ElectionType.Both)
  const partyOptions = newCandidate.election_type === ElectionType.Municipality || newCandidate.election_type === ElectionType.Both ? municipalityParties : countyParties

  const [homeMunicipality, setHomeMunicipality] = useState<string>()

  const municipalityOptions = useMemo(() => municipalities.map(m => ({ key: m.municipality_id, title: language === 'sv' ? m.name_sv : m.name_fi })), [language])

  const selectedMunicipality = useMemo(() => municipalityConstituencies.find(m => m.official_id === homeMunicipality), [homeMunicipality, municipalityConstituencies])
  const selectedCounty = useMemo(() => {
    const municipality = municipalities.find(m => m.municipality_id === homeMunicipality)
    const county = countyConstituencies.find(c => c.official_id === municipality?.county_id)
    return county
  }, [homeMunicipality, countyConstituencies])

  useEffect(() => {
    const authenticateUser = async () => {
      const urlQueryParams = new URLSearchParams(location.search)
      const codeFromUrl = String(urlQueryParams.get('code'))

      try {
        const isNew = await authenticateCandidate(codeFromUrl)
        // Fix on race condition that prevents user from being logged in when user is returning.
        if (isNew) {
          await fetchElectionData()
          navigate('/luo-profiili', { replace: true })
        }
      } catch (err) {
        setError(true)
        // If login code is not valid, redirect to NoAuth page
        window.location.replace('/')
      }
    }
    if (location.search.includes('code=')) {
      authenticateUser()
    }
    if (location.search.includes('error=')) {
      notificationStore.createNotification(t('register.error'), 'error')
      window.location.replace('/tunnistaudu')
    }
    return setError(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const setValue = (propertyName: string, val: string | number | undefined) => {
    setNewCandidate({
      ...newCandidate,
      [propertyName]: val
    })
  }

  const handleElectionSelection = (electionType: string) => {
    const result = {
      ...newCandidate,
    }

    if (electionType === ElectionType.Municipality) {
      result.election_type = ElectionType.Municipality
    }
    if (electionType === ElectionType.County) {
      result.election_type = ElectionType.County
    }
    if (electionType === ElectionType.Both) {
      result.election_type = ElectionType.Both
    }
    const oldElectionType = newCandidate.election_type
    const newElectionType = result.election_type

    if (oldElectionType !== newElectionType) {
      if (oldElectionType === ElectionType.Municipality && newElectionType === ElectionType.County) {
        result.party_id = undefined
        result.extra_party_id = undefined
      }
      if (oldElectionType === ElectionType.County && newElectionType === ElectionType.Municipality) {
        result.extra_party_id = undefined
        result.party_id = undefined
      }
      if (oldElectionType === ElectionType.Both && newElectionType === ElectionType.County) {
        result.party_id = newCandidate.extra_party_id
        result.extra_party_id = undefined
      }
    }

    setNewCandidate(result)
  }

  const everythingAnswered = () => {
    let result = Boolean(
      newCandidate.first_name &&
      newCandidate.first_name.trim().length > 0 &&
      newCandidate.last_name &&
      newCandidate.last_name.trim().length > 0 &&
      newCandidate.email &&
      newCandidate.email.trim().length > 0 &&
      emailRegex.test(newCandidate.email) &&
      (selectedCounty || selectedMunicipality) &&
      newCandidate.party_id
    )

    if (newCandidate.election_type === ElectionType.Both) {
      result = Boolean(selectedMunicipality &&
        newCandidate.party_id &&
        newCandidate.extra_party_id &&
        selectedCounty
      )
    }

    return result
  }

  return (
    <MainWrapper>
      {!loading ? (
        <>
          <TopNavBar
            translations={{
              electionName: t('topNav.title'),
              profile: t('topNav.profile'),
              logout: t('topNav.logout')
            }}
          />
          <MainContainer>
            <WelcomeBox>
              <h1>{t('register.pageHeader')}</h1>
              <p>{t('register.pageInfo')}</p>
              <FormInput
                label={t('register.firstname')}
                required
                type="text"
                value={newCandidate.first_name}
                onChange={val => setValue('first_name', val)}
                autocomplete="given-name"
              />
              <FormInput
                label={t('register.lastname')}
                required
                type="text"
                value={newCandidate.last_name}
                onChange={val => setValue('last_name', val)}
                autocomplete="family-name"
              />
              <FormInput
                label={t('register.email')}
                required
                type="email"
                value={newCandidate.email}
                onChange={val => setValue('email', val)}
                autocomplete="email"
              />

              <FormDropdown required label={t('register.homeMunicipality')} options={municipalityOptions} allowSearch selected={homeMunicipality} onSelect={setHomeMunicipality} />

              <Fieldset disabled={homeMunicipality == null} onChange={(e: any) => handleElectionSelection(e.target.value)}>
                <Span as="legend" $fontSize="xs" $fontWeight="bold">{t('register.electionSelection')}</Span>

                <Inline as="label" $alignY="center">
                  <input type="radio" disabled={homeMunicipality === HELSINKI} name="election-type" value={ElectionType.County} checked={newCandidate.election_type === ElectionType.County} />
                  <Span>{t('register.isCandidateForCounty')}</Span>
                </Inline>

                <Inline as="label" $alignY="center">
                  <input type="radio" name="election-type" value={ElectionType.Municipality} checked={newCandidate.election_type === ElectionType.Municipality} />
                  <Span>{t('register.isCandidateForMunicipality')}</Span>
                </Inline>

                <Inline as="label" $alignY="center">
                  <input disabled={homeMunicipality === HELSINKI} type="radio" name="election-type" value={ElectionType.Both} checked={newCandidate.election_type === ElectionType.Both} />
                  <Span>{t('register.isCandidateForBoth')}</Span>
                </Inline>
              </Fieldset>

              {newCandidate.election_type === ElectionType.Both && (
                <ElectionBox>
                  <Span $fontWeight="bold">{t('register.countyElectionOptions')}</Span>
                  <Span $fontSize="xs" $fontWeight="bold">{t('register.basedOnHomeMunicipality')}</Span>
                  <Span >{language === 'sv' ? selectedCounty?.name_sv : selectedCounty?.name_fi}</Span>

                  <FormDropdown
                    label={t('register.party')}
                    required
                    selected={newCandidate.extra_party_id}
                    placeHolder={t('register.selectParty')}
                    options={countyParties
                      .filter(p => p.constituency_id === null || p.constituency_id === selectedMunicipality?.id)
                      .map(p => ({ key: p.id, title: p.name_fi }))}
                    onSelect={(val: number) => {
                      if (parties.find(p => p.id === val)?.election_type === ElectionType.Both && newCandidate.party_id == null) {
                        setNewCandidate({ ...newCandidate, extra_party_id: val, party_id: val })
                      } else {
                        setValue('extra_party_id', val)
                      }
                    }}
                  />
                </ElectionBox>
              )}

              {newCandidate.election_type && (
                <ElectionBox>
                  <Span $fontWeight="bold">
                    {newCandidate.election_type === ElectionType.Both && t('register.municipalityElectionOptions')}
                    {newCandidate.election_type === ElectionType.Municipality && t('register.municipalityElectionOptions')}
                    {newCandidate.election_type === ElectionType.County && t('register.countyElectionOptions')}
                  </Span>
                  <Span $fontSize="xs" $fontWeight="bold">{t('register.basedOnHomeMunicipality')}</Span>
                  {matchesMunicipalityElection(newCandidate) && (
                    <Span >{(language === 'sv' ? selectedMunicipality?.name_sv : selectedMunicipality?.name_fi) ?? t('register.constituencyNotFound')}</Span>
                  )}
                  {isCountyElection(newCandidate) && (
                    <Span >{(language === 'sv' ? selectedCounty?.name_sv : selectedCounty?.name_fi) ?? t('register.constituencyNotFound')}</Span>
                  )}
                  <FormDropdown
                    label={t('register.party')}
                    required
                    selected={newCandidate.party_id}
                    placeHolder={t('register.selectParty')}
                    options={partyOptions
                      .filter(p => p.constituency_id === null || p.constituency_id === selectedCounty?.id || p.constituency_id === selectedMunicipality?.id)
                      .map(p => ({ key: p.id, title: p.name_fi }))}
                    onSelect={val => setValue('party_id', Number(val))}
                  />
                </ElectionBox>
              )}
              <PillButton disabled={!everythingAnswered()} intent="yleprimary" onClick={() => createNewCandidate(
                matchesMunicipalityElection(newCandidate) ? selectedMunicipality?.id : selectedCounty?.id,
                matchesCountyElection(newCandidate) ? selectedCounty?.id : undefined
              )}>
                {t('register.register')}
              </PillButton>
              {error && (
                <ErrorBg>
                  <ErrorModal>
                    <h1>{t('register.errorHeader')}</h1>
                    <p>{t('register.errorInfo')}</p>
                    <PillButton intent="yleprimary" onClick={() => setError(false)}>
                      Ok
                    </PillButton>
                  </ErrorModal>
                </ErrorBg>
              )}
            </WelcomeBox>
          </MainContainer>
        </>
      ) : (
        <ErrorBg>
          <PageLoader />
        </ErrorBg>
      )
      }
    </MainWrapper >
  )
})

export const CreateNewPage = CreateNewPageComponent

const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: ${layoutSpacing.small};
  border: none;
  width: 100%;
  padding: 0 0 ${layoutSpacing.small};
  legend {
    
  } 
`

const ElectionBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${layoutSpacing.small};
  border: 1px solid ${color.GRAY_50};
  border-radius: ${radius.MEDIUM};
  width: calc(100% + ${layoutSpacing.small} * 2);
  padding: ${layoutSpacing.small};
  margin-bottom: ${layoutSpacing.small};
`

const ErrorBg = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 99;
  svg {
    margin-top: 20%;
  }
`

const ErrorModal = styled.div`
  max-width: 450px;
  position: absolute;
  background-color: white;
  border-radius: ${radius.MEDIUM};
  top: 20%;
  margin: 20px;
  h1 {
    color: ${color.RED_50};
    margin: ${spacing.SPACING_24};
  }
  p {
    margin: ${spacing.SPACING_24};
  }
  button {
    margin: ${spacing.SPACING_24} ${spacing.SPACING_24} ${spacing.SPACING_24} auto !important;
  }
`
